import { TextConfig } from "./TextConfig";

export class APIAText extends TextConfig {
    constructor() {
        super()
        this.brand = 'Apia Life';
        this.title = 'Apia';
        this.phoneNumber = '1300 513 300';
        this.privacyPolicy = 'https://www.apia.com.au/privacy.html';
        this.terms = 'Apia Life Limited';
        this.pleaseNote = '1300 513 300 (Mon-Fri 8am-7pm AET)';
        this.footer = 'TAL Life Limited ABN 70 050 109 450';
        this.aboutUs = 'https://www.apia.com.au/about-us.html';
        this.security = 'https://www.apia.com.au/cookie-and-data-policy.html';
        this.complaints = 'mailto:customerservice@apialifeinsurance.com.au';
        this.disclaimer = 'https://www.apia.com.au/code-of-practice.html#licop';
        this.contactTime = 'Mon-Fri 8am-7pm AET';
        this.disclaimerBrand = 'Apia Life';
        this.policyNotFound = 'The information you have entered is incorrect, please try again. For assistance, contact our support team on the number listed on your policy document.';
    };
}

// Text for APIA off sale (SourceSystemCode = LFH)
export class APIAOffSaleText extends APIAText {
    constructor() {
        super()   
        this.phoneNumber = "1800 608 549";
        this.pleaseNote = '1800 608 549, 8am to 6pm AEST Monday to Friday';
        this.contactTime = '8am-6pm AEST Monday to Friday';
        this.contactTimeFormat = ', 8am to 6pm AEST Monday to Friday';      
    };
}

// Text for APIA on sale ( SourceSystemCode = ETH)
export class APIAOnSaleText extends APIAText {
    constructor() {
        super()
        this.phoneNumber = "1300 513 300";
        this.pleaseNote = '1300 513 300, 8am - 7pm, Monday to Friday, AEST';
        this.contactTime = 'Mon-Fri 8am-7pm AET';
        this.contactTimeFormat = ', 8am - 7pm, Monday to Friday, AEST';
    };
}