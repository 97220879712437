import styled from '@emotion/styled';
import { getGelTokens } from '@tal-gel/theming';

const Text = styled.div(({ color, bold, highlight, warning, rightAligned }) => {
  return {
    fontFamily: getGelTokens().global.fontFamilyCondensed,
    fontSize: getGelTokens().global.fontSizeBodyDefault,
    color: color || getGelTokens().global.themeColorTextDefault,
    fontWeight: bold && getGelTokens().global.fontWeightBold,
    ...highlight && {
      fontSize: getGelTokens().global.fontSizeBodyXLarge,
      fontWeight: getGelTokens().global.fontWeightBold
    },
    ...warning && { color: getGelTokens().global.themeColorDanger },
    ...rightAligned && { textAlign: 'right' }
  };
});

export default Text;
