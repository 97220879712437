import React, { useContext } from "react";
import { 
  GelBoxLayout, GelRowLayout, GelButton, 
  GelHeading6, GelParagraph, GelScreenDetectorContext
} from '@tal-gel/components';

import { Text, tableRowStyle, h3FontSizeStyle } from '../Shared';

const DirectDebit = React.memo(({ 
  policyNumber,
  directDebitDetail = {}
}) => {
  const { screen, isXsScreen } = useContext(GelScreenDetectorContext);

  const getPaymentMethodDisplay = () => {
    if (directDebitDetail.paymentMethod === 'DD') {
      return `Bank account ending in ${directDebitDetail.number}`;
    } else if (directDebitDetail.paymentMethod === 'CC') {
      return `Credit card ${directDebitDetail.number}`;
    }
    return;
  };
  const paymentMethodDisplay = getPaymentMethodDisplay();

  const onUpdateClick = () => {
    window.open(
      `/dashboard/payment-advice-form?policyId=${policyNumber}`,
      '_self'
    );
  };

  return (
    <GelRowLayout gutter={
      isXsScreen(screen) ? 'medium' : 'large'}
    >
      <GelHeading6 as="h3" style={h3FontSizeStyle}>
        Direct Debit
      </GelHeading6>

      <GelRowLayout gutter="large">
        { directDebitDetail.number ? 
          <GelRowLayout>
            <GelBoxLayout 
              distribution="start"
              space={['auto']} 
              gap="none" 
              style={tableRowStyle}
            >
              <Text bold>Payment Method</Text>
              <Text rightAligned>
                { paymentMethodDisplay }
              </Text>
            </GelBoxLayout>
            { directDebitDetail.accountName && <>
            <GelBoxLayout 
              distribution="start"
              space={['auto']} 
              gap="none" 
              style={tableRowStyle}
            >
              <Text bold>Account Name</Text>
              <Text rightAligned>
                { directDebitDetail.accountName }
              </Text>
            </GelBoxLayout>
            </> }

            <GelBoxLayout
              distribution="start"
              space={['auto']} 
              gap="none"   
              style={tableRowStyle}
            >
              <Text bold>Frequency</Text>
              <Text rightAligned>
                { directDebitDetail.frequency }
              </Text>
            </GelBoxLayout>
          </GelRowLayout>
          :
          <GelParagraph>
            Direct debit is not set up for this policy.
          </GelParagraph>
        }
        <GelButton 
          secondary medium width={165}
          onClick={onUpdateClick}
        >
          Update
        </GelButton>
      </GelRowLayout>
    </GelRowLayout>
  );
});

export default DirectDebit;
