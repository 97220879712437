import React from "react";
import { 
  GelBoxLayout, GelContainerLite, GelButton, 
  GelSelect, GelParagraph
} from '@tal-gel/components';


const Pagination = React.memo(({    
  totalPages, 
  hasNext, 
  hasPrevious,
  handlePageChange,
  onNextClick,
  onBackClick,
  selectedPage
}) => {
  const pageNumbers = [];
  for(let i= 1 ; i <=totalPages ; i++ )
  {
    pageNumbers.push({ label: i, value: i });
  }

  return (
    <GelContainerLite>
    <GelBoxLayout distribution="start" space={"auto"}>
        <GelButton secondary medium onClick={onBackClick} iconLeft="ArrowLeft" disabled ={!hasPrevious}>
          Back
        </GelButton>
        <GelBoxLayout distribution="start" space={["auto"]} gap="small">
          <GelSelect
            onChange={handlePageChange}            
            options={pageNumbers}
            value={selectedPage}
          />
          <GelParagraph>of {totalPages}</GelParagraph>
        </GelBoxLayout>
        <GelButton primary medium disabled ={!hasNext}
           onClick={onNextClick} iconRight="ArrowRight">
          Next
        </GelButton>
      </GelBoxLayout>

  </GelContainerLite>    
  );
} );

export default Pagination;
