const processTransaction = `query bpointTransaction($processTransactionInput: processTransactionInput) {
    processPayment(processTransactionInput: $processTransactionInput) {
    receiptNumber
    authToken
    paymentDate
    txnStatus
    }
    }`;

export {
    processTransaction
};