const getPolicy = `query policy($policyDetailInput: policyDetailInput) {
    policyDetails(policyDetailInput: $policyDetailInput) {
            isDuplicate
            isValidRecaptcha
            authToken
            lockoutStatus
            policyDetails {
                paymentAmount
                overdueAmount
                isOverdue
                isValidOverdue
                merchantId
                policyNo
                brandCode
                brandName
                policyTypeCodeAllowed
                sourceSystemCode
                businessArea
            }
        }
    }`;

export {
    getPolicy
};