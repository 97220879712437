import React, { useState, useEffect } from "react";
import { getGelTokens } from '@tal-gel/theming';
import {
    GelIcon, GelLink, GelForm,
    GelParagraph,
    GelFormField, GelSpinner, GelBoxLayout,
    GelButton, useGelFormData,
    GelModal, GelEmailInput,
    GelCaption
} from '@tal-gel/components';

import CustomContainer from '../Shared/CustomContainer';
import { ProcessTransactionQuery } from '../../services/unAuthenticatedPaymentService';
import { processTransaction } from '../../services/unAuthenticPaymentQueries/processTransaction';
import { ProcessReceiptRequest } from '../../services/unAuthenticatedPaymentService'
import { processReceipt } from '../../services/unAuthenticPaymentQueries/processReceipt'
import helpers from '../Shared/helpers';
import Divider from '../Shared/Divider';
import { boldStyle, TxnStatus } from "../Shared";
import sendAdobeTag from '../../services/sendAdobeTag';

const SuccessOrErrorPayment = React.memo(({ width }) => {
    const {
        formData, // form data of this step
        onFormDataChange,
        onNextStep,
        multiStepFormData,
        onMultiStepFormDataReset // overall form data of all steps
    } = useGelFormData(
        {
            // initial data of this step

        },
        // Step name, note that `formData` is to be scoped
        // to this name in the overall multi step form data
        // object, Eg: { step2: formData }
        "SuccessOrErrorPayment"
    );
    const [receiptNumber, setReceiptNumber] = useState();
    const [paymentTxnStatus, setPaymentTxnStatus] = useState();
    const [generalError, setGeneralError] = useState(false);
    const [loading, setLoading] = useState();
    const [paymentDate, setPaymentDate] = useState();
    const currentBrand = helpers.GetCurrentBrand();
    const [displayText] = useState(helpers.SetCurrentBrandText(currentBrand, multiStepFormData.policyNumber.businessArea, multiStepFormData.policyNumber.sourceSystemCode));
    const [receiptModalState, setReceiptModalState] = useState({
        visible: false,
        buttonText: null,
        title: null,
        subTitle: null,
        emailVisibible: null,
        resetModal: false
    })

    width = helpers.GetComponentScreenWidth();

    // onload - api call to process transaction -api call
    useEffect(() => {
        if (multiStepFormData.PaymentDetails.attachPaymentMethodStatus === 'success') {
            //api call to process transaction -api call and set receiptnumber when the payment is successfull
            //call payment api to get authkey with selected amount
            (async () => {
                setLoading(true);
                const response = await ProcessTransactionQuery({
                    query: processTransaction, processTransactionInput: { 
                        "authKey": multiStepFormData.ConfirmPaymentDetails.authKey,
                        "amount": multiStepFormData.ConfirmPaymentDetails.selectedAmount,
                        "merchantId": multiStepFormData.policyNumber.merchantId,
                        "policyNo": multiStepFormData.policyNumber.policyNo, 
                        "brandCode": multiStepFormData.policyNumber.brandCode, 
                        "sourceSystemCode": multiStepFormData.policyNumber.sourceSystemCode,
                        "authToken": helpers.GetAuthTokenCookies()
                    }
                });
                setPaymentTxnStatus(TxnStatus.FAILED);
                if (response.error) {
                    setLoading(false);
                    callAdobeTagPush(false);
                }
                else {
                    setLoading(false);
                    if (response.data.processPayment) {                        
                        setPaymentTxnStatus(response.data.processPayment.txnStatus);
                        if (response.data.processPayment.receiptNumber) {                            
                            //set authkey
                            setReceiptNumber(response.data.processPayment.receiptNumber);
                            setPaymentDate(new Date());
                            helpers.SetAuthCookies(response.data.processPayment.authToken);
                        }
                        callAdobeTagPush(response.data.processPayment.txnStatus.toUpperCase() == "SUCCESSFUL" ? true : false);
                    }
                    else 
                        callAdobeTagPush(false);
                }                
            })();
        } else {
            setGeneralError(true);
            callAdobeTagPush(false);
        }          
    }, []);

    const callAdobeTagPush = (isSuccessful) => {
        // Send data to marketing team
        sendAdobeTag({ brand: multiStepFormData.policyNumber.brandCode, pageEventName: "Form Confirmation", formStep: (isSuccessful) ? "Payment Confirmation" : "Payment Failed"});
    }    

    const sendReceipt = (email) => {
        if(receiptModalState.resetModal)
        {
            setReceiptModalState({visible: false});
            formData.email = "";
        }
        else{
            setReceiptModalState({visible: false});           
            (async () => {
                setLoading(true);                    
                const response = await ProcessReceiptRequest({
                    query: processReceipt, correspondenceInput: { 
                        "policyNumber": multiStepFormData.policyNumber.policyNo,
                        "dateOfBirth": isNaN(multiStepFormData.policyNumber.dOB) === true ? helpers.getDateInMMddYYYY(multiStepFormData.policyNumber.dOB): '',
                        "brand": multiStepFormData.policyNumber.brandCode,
                        "receiptNumber": receiptNumber,
                        "paymentAmount": multiStepFormData.ConfirmPaymentDetails.selectedAmount,
                        "paymentDate": paymentDate,
                        "emailAddress": email,
                        "distributionChannel": "Email",
                        "authToken": helpers.GetAuthTokenCookies()
                    }
                });
                setLoading(false);
                try {
                    if (response.data.sendCorrespondence.isSent) {
                        helpers.SetAuthCookies(response.data.sendCorrespondence.authToken);
                        onReceiptConfirmation("A receipt has been sent to " + email);            
                    }
                    else {
                        onReceiptConfirmation(response.data.sendCorrespondence.isSentMaxNoOfTimes ? "Maximum number of emails already sent for this receipt." : "Your receipt was not sent. Please try again.");
                    }
                }
                catch {
                    onReceiptConfirmation("Your receipt was not sent. Please try again.");
                }
            })();           
        }
    }

    const onReceiptConfirmation = (message) => {
        setReceiptModalState({visible: true,
            buttonText: "OK",
            subTitle: message,
            emailVisibible: "hidden",
            resetModal: true })
    };

    const onSubmit = () => {
        //reset the form and go to next step which will be setp1
        onMultiStepFormDataReset();
        onNextStep();
    };

    const onResponse = (response) => {
        console.log("response", response);
    };

    const onError = (error) => {
        console.error("error", error);
    };

    // temporary solution for check icon. Will contact gel team to request consistent naming across brands.
    const setCheckIcon = () => {
        const checkSmall = ['tal', 'astn', 'apa', 'aam'];

        if (checkSmall.includes(currentBrand.toLowerCase()))
            return "CheckSmall";
        else 
            return "Check";
    };

    return (<>
        {loading && <GelSpinner large overlay></GelSpinner>}
        {!loading && <CustomContainer
            width={width}
            mt={getGelTokens().global.sizeBaseX6}
            mb={getGelTokens().global.sizeBaseX6}
            ml='auto'
            mr='auto'
            padding={getGelTokens().global.sizeBaseX8}
            borderRadius={getGelTokens().global.sizeBaseHalf}
            backgroundColor={getGelTokens().global.themeColorBackgroundDefault}>
            {paymentTxnStatus === TxnStatus.SUCCESSFUL && receiptNumber && <>
                <GelBoxLayout space={["auto", 1]} distribution="start" alignment="start">
                    <GelIcon name={setCheckIcon()} color={getGelTokens().global.themeColorIconDefault}></GelIcon>
                    <>
                        <GelParagraph large style={{...boldStyle}} marginBottom={getGelTokens().global.sizeBaseX2}>Thank you for your successful payment</GelParagraph>
                        <hr />
                        <GelCaption
                            condensed>
                            <strong>Please note: Payments can take up to 5 days to be processed.</strong>
                        </GelCaption>
                        <GelParagraph marginBottom={getGelTokens().global.sizeBaseX2} marginTop={getGelTokens().global.sizeBaseX2}>
                            Your receipt number is {receiptNumber}. Please take a note of this receipt number for your
                            records.
                        </GelParagraph>
                        <GelParagraph marginBottom={getGelTokens().global.sizeBaseX8}>
                            If you have any questions, please contact us on {displayText.phoneNumber}{displayText.contactTimeFormat ? displayText.contactTimeFormat.replace(/\.$/, "") :  displayText.contactTime ? " (" + displayText.contactTime + ")"  :  " ()"}.
                        </GelParagraph>
                    </>
                </GelBoxLayout>
                <GelForm onSubmit={onSubmit} onResponse={onResponse} onError={onError}>
                    <GelFormField>
                        <GelButton
                            name="" // Makes it a managed component
                            secondary
                            medium
                            disabled={!helpers.EnableReceipt(multiStepFormData.policyNumber.brandCode)}
                            onClick={() => {
                                setReceiptModalState({visible: true,
                                    buttonText: "Send Receipt",
                                    title: "Send Receipt",
                                    subTitle: "Your Email Address" }); 
                                    formData.email = ""
                            }}>
                            Send Receipt
                        </GelButton>
                    </GelFormField>
                    <GelFormField>
                        <GelButton name="" medium primary stretch submit>
                            Return Home
                        </GelButton>
                    </GelFormField>
                </GelForm>
            </>}
            {paymentTxnStatus === TxnStatus.FAILED && <>
                <GelBoxLayout space={["auto", 1]} distribution="start" alignment="start">
                    <GelIcon
                        name="AlertTriangle"
                        color={getGelTokens().global.themeColorIconDanger}
                    ></GelIcon>
                    <>
                    <GelParagraph large style={{...boldStyle}}>Your payment was not successful</GelParagraph>
                        <GelParagraph marginBottom={getGelTokens().global.sizeBaseX2}>
                            Unfortunately, we have been unable to complete your payment request.
                        </GelParagraph>
                        <GelParagraph marginBottom={getGelTokens().global.sizeBaseX8}>
                            Please try again later or call us on
                            <GelLink inline>{displayText.phoneNumber}</GelLink>{displayText.contactTimeFormat ? displayText.contactTimeFormat.replace(/\.$/, "") : displayText.contactTime ?? ""}
                        </GelParagraph>
                    </>
                </GelBoxLayout>
                <GelButton medium secondary stretch onClick={onSubmit}>
                    Try again
                </GelButton>
            </>}
            {paymentTxnStatus === TxnStatus.UNSUCCESSFUL && <>
                <GelBoxLayout space={["auto", 1]} distribution="start" alignment="start">
                    <GelIcon
                        name="AlertTriangle"
                        color={getGelTokens().global.themeColorIconDanger}
                    ></GelIcon>
                    <>
                    <GelParagraph large style={{...boldStyle}}>Your payment was not successful</GelParagraph>
                        <GelParagraph marginTop={getGelTokens().global.sizeBaseX8} marginBottom={getGelTokens().global.sizeBaseX8}>
                            This payment has been rejected by the financial institution.
                        </GelParagraph>
                    </>
                </GelBoxLayout>
                <GelButton medium secondary stretch onClick={onSubmit}>
                    Try again
                </GelButton>
            </>}
            {generalError && <>
                <GelBoxLayout space={["auto", 1]} distribution="start" alignment="start">
                    <GelIcon
                        name="AlertTriangle"
                        color={getGelTokens().global.themeColorIconDanger}
                    ></GelIcon>
                    <>
                        <GelParagraph large style={{...boldStyle}}>Something went wrong</GelParagraph>
                        <GelParagraph marginBottom={getGelTokens().global.sizeBaseX8}>
                            Unfortunately an error has occurred, please try again later or call us on
                            <GelLink inline>{displayText.phoneNumber}</GelLink>{displayText.contactTimeFormat ? displayText.contactTimeFormat.replace(/\.$/, "") :  displayText.contactTime ? "  (" + displayText.contactTime + ")"  :  "  (Mon-Fri 9am-5pm AET)"}
                        </GelParagraph>
                    </>
                </GelBoxLayout>
                <GelButton medium secondary stretch onClick={onSubmit}>
                    Try again
                </GelButton>
            </>}
            
            <GelModal
                width="sm"
                open={receiptModalState.visible} 
                onClose={() => setReceiptModalState({visible: false})} 
                title={receiptModalState.title}
                closeOnBackdropClick={false}>
                <GelForm labelAtTop
                    disableOnSubmit
                    preventSubmitOnInvalid
                    onResponse={onResponse}
                    onError={onError}
                    style={{  marginTop: receiptModalState.emailVisibible == "hidden" ? 12:0 }}
                    onSubmit={() => sendReceipt(formData.email)}>
                <GelFormField label={receiptModalState.subTitle}>
                <GelEmailInput
                    name="email"
                    value={formData.email}
                    onChange={onFormDataChange}
                    required
                    type={receiptModalState.emailVisibible}
                    errorMsg={{
                        required: 'Email is required',
                        format: 'Invalid format'
                    }}/>
                </GelFormField>
                <Divider mt={32} mb={32}></Divider>
                <GelFormField>
                    <GelButton name="" primary medium submit stretch>
                        {receiptModalState.buttonText}
                    </GelButton>
                </GelFormField>
                </GelForm>
            </GelModal>
        </CustomContainer>}</>
    );
});

export default SuccessOrErrorPayment;