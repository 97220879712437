import React, { useContext } from "react";
import styled from '@emotion/styled';
import { roundToDecimals } from '@tal-gel/core';
import { getGelTokens } from '@tal-gel/theming';
import { 
  GelContainerLite, GelBoxLayout, GelRowLayout, 
  GelHeading6, GelParagraph, GelScreenDetectorContext
} from '@tal-gel/components';
import { 
  Text, tableRowStyle, 
  greyBackgroundStyle, h3FontSizeStyle 
} from '../Shared';

const NextPayment = React.memo(({ nextPaymentDetail = {} }) => {
  const { screen, isXsScreen } = useContext(GelScreenDetectorContext);

  const getPaymentMethodDisplay = () => {
    if (nextPaymentDetail.paymentMethod === 'DD') {
      return [
        <span key={0}>Direct debit from bank account ending</span>, 
        <br key={1} />, 
        <span key={2}>in { nextPaymentDetail.number }</span>
      ];
    } else if (nextPaymentDetail.paymentMethod === 'CC') {
      return `Credit card ${nextPaymentDetail.number}`;
    }
    return;
  };
  const paymentMethodDisplay = getPaymentMethodDisplay();

  const getDueDate = () => {
    if (nextPaymentDetail.dueDate) {      
      const dueDateParts = nextPaymentDetail.dueDate.split('-');
      return dueDateParts[2] + '/' + dueDateParts[1] + '/' + dueDateParts[0];
    }
  };
  
  return (
    <GelContainerLite 
      data-testid="next-payments-section"
      gutter={{ 
        left: getGelTokens().global.sizeBaseX6, 
        right: getGelTokens().global.sizeBaseX6,
        top: 'large', bottom: 'xlarge' 
      }}
      style={greyBackgroundStyle}
    >
      <GelRowLayout gutter="large">
        <GelRowLayout gutter="medium">
          <GelRowLayout gutter="small">
            <GelHeading6 as="h3" style={h3FontSizeStyle}>
              Next payment
            </GelHeading6>
            <GelParagraph>
              Details of your next payment for 
              {` ${nextPaymentDetail.productName} #${nextPaymentDetail.customerPolicyNumber}`}
            </GelParagraph>
          </GelRowLayout>

          { nextPaymentDetail.isOverdue &&
            <GelRowLayout>
              <GelParagraph style={{ 
                fontFamily: getGelTokens().global.fontFamilySans,
                color: getGelTokens().global.themeColorDanger,
                fontWeight: getGelTokens().global.fontWeightBold,
                padding: getGelTokens().global.sizeBaseX4,
                border: `1px solid ${getGelTokens().global.themeColorDanger}`
              }}>
                Your premium is overdue
              </GelParagraph>
            </GelRowLayout>
          }
          <GelRowLayout style={
            !isXsScreen(screen) ? { width: '70%' } : {}
          }>
            <GelBoxLayout 
              distribution="start"
              space={['auto']} 
              gap="small" 
              style={tableRowStyle}
            >
              <Text bold>Amount</Text>
              <Text highlight rightAligned>
                ${ roundToDecimals(nextPaymentDetail.amount,2) }
              </Text>
            </GelBoxLayout>

            <GelBoxLayout
              distribution="start"
              space={['auto']} 
              gap="small"   
              style={tableRowStyle}
            >
              <Text bold warning={nextPaymentDetail.isOverdue}>
                Due date
              </Text>
              <Text rightAligned warning={nextPaymentDetail.isOverdue}>
                {getDueDate()}
              </Text>
            </GelBoxLayout>

            { nextPaymentDetail.number && 
              <GelBoxLayout 
                distribution="start"
                space={['auto']} 
                gap="small" 
                style={tableRowStyle}
              >
                <Text bold>Payment method</Text>
                <Text rightAligned>
                  { paymentMethodDisplay }
                </Text>
              </GelBoxLayout>
            }
          </GelRowLayout>
        </GelRowLayout> 

        <GelParagraph>
          <Attention>Please Note: </Attention>
          <span>Payments made in the last 3 days may still be pending</span>
        </GelParagraph>
      </GelRowLayout>
    </GelContainerLite>     
  );
});

const Attention = styled.span({
  fontFamily: getGelTokens().global.fontFamilySans,
  fontWeight: getGelTokens().fontWeightBold,
  color: getGelTokens().global.themeColorTextDefault
});

export default NextPayment;
