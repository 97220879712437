import { TextConfig } from "./TextConfig";

export class SuncorpText extends TextConfig {
    constructor() {
        super()
        this.title = 'SUNCORP';
        this.privacyPolicy = 'https://www.suncorp.com.au/content/dam/suncorp/corporate/documents/legal/privacy/suncorp-life-insurance-privacy-statement.pdf';
        this.terms = 'TAL Life Limited';
        this.footer = 'TAL Life Limited ABN 70 050 109 450';
        this.aboutUs = 'https://www.suncorp.com.au/about-us';
        this.security = 'https://www.suncorp.com.au/about-us/legal/cookie-and-data-policy.html';
        this.complaints = 'mailto: customerservice@suncorplifeinsurance.com.au';
        this.disclaimer = 'https://www.suncorp.com.au/about-us/legal/online-terms.html#1';
        this.contactTime = '8am-8pm Eastern Standard Time Monday to Friday';
        this.superContact = '1800 030 733 (8am-6pm AEST Monday to Friday)';
        this.disclaimerBrand = 'TAL Life Limited';
        this.policyNotFound = "The information you have entered is incorrect, please try again. For assistance, contact our support team on the number listed on your policy document.";
    };
}

// Text for suncorp off sale DIRECT (BusinessCode = DIRECT and SourceSystemCode = LFH)
export class SuncorpOffSaleText extends SuncorpText {
    constructor() {
        super()
        this.brand = 'Suncorp Life';
        this.phoneNumber = "1800 604 689";
        this.pleaseNote = '1800 604 689, 8am to 6pm AEST Monday to Friday';
        this.contactTime = ', 8am to 6pm AEST Monday to Friday';
        this.contactTimeFormat = ', 8am to 6pm AEST Monday to Friday'; 
      };
}

// Text for suncorp off sale RETAIL (BusinessCode = RETAIL and SourceSystemCode = LFH)
export class SuncorpRetailText extends SuncorpText {
    constructor() {
        super()
        this.brand = 'Suncorp Life';
        this.phoneNumber = "1800 030 733";
        this.pleaseNote = '1800 030 733 8am-6pm AEST Monday to Friday';
        this.contactTime = ' 8am-6pm AEST Monday to Friday.';
        this.contactTimeFormat = ' 8am-6pm AEST Monday to Friday.';
    };
}

// Text for suncorp on sale (BusinessCode = DIRECT and SourceSystemCode = ETH)
export class SuncorpOnSaleText extends SuncorpText {
    constructor() {
        super()
        this.brand = 'Suncorp Life'
        this.phoneNumber = "1300 615 699";
        this.pleaseNote = '1300 615 699, 8am-7pm, Monday to Friday, AEST';
        this.contactTime = ', 8am-7pm, Monday to Friday, AEST';
        this.contactTimeFormat = ', 8am-7pm, Monday to Friday, AEST';
       };
}