import React, { useContext, useEffect } from "react";
import { isFunction } from '@tal-gel/core';
import { getGelTokens } from '@tal-gel/theming';
import { 
  GelContainerLite, GelRowLayout, GelSelect, 
  GelHeading6, GelParagraph, GelScreenDetectorContext
} from '@tal-gel/components';

import { whiteBackgroundStyle, Brands } from '../Shared';

const SelectPolicy = React.memo(({ 
  brand, loading, error, policies = [], onPolicySelected 
}) => {
  const { screen, isXsScreen } = useContext(GelScreenDetectorContext);
  const onlyOnePolicy = () => policies.length === 1;
  const isTalBrand = () => brand === Brands.TAL;

  useEffect(() => {
    if (onlyOnePolicy()) {
      onPolicySelected(policies[0].value);
    }
  }, [policies]);

  const onChange = ({ target: { value } }) => {
    isFunction(onPolicySelected) && onPolicySelected(value);
  };

  const getPlaceholder = () => {
    return loading ? 'Loading policies...' : 'Select a policy';
  };

  const getCaption = () => {
    return error && error.message;
  };

  return (<>
    <GelContainerLite 
      gutter={{ 
        left: getGelTokens().global.sizeBaseX6, 
        right: getGelTokens().global.sizeBaseX6, 
        top: 'large', bottom: 'xlarge' 
      }} 
      style={whiteBackgroundStyle}
    >
      <GelRowLayout gutter="small">
        <GelHeading6 
          as="h2" 
          style={{ 
            color: !isTalBrand() && getGelTokens().brand.brandColorPrimary2
          }}
        >
          Select a policy
        </GelHeading6>

        <GelRowLayout gutter="medium">
          <GelParagraph>
            Select a policy you want to view payments for
          </GelParagraph>

          <GelSelect 
            placeholder={getPlaceholder()}
            options={policies}
            onChange={onChange}
            {...!isXsScreen(screen) && 
              { width: '50%' }
            }
            {...onlyOnePolicy() && 
              { value: policies[0].value }
            }
            readOnly={onlyOnePolicy() || !!error}
            error={!!error}
            caption={getCaption()}
          />
        </GelRowLayout>
      </GelRowLayout>
    </GelContainerLite>
  </>);
});

export default SelectPolicy;
