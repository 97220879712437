import React, { useState } from "react";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";
import StyledNavbarContainer from '../Shared/StyledNavbarContainer';
import {
    GelContainerLite,
    GelParagraph,
    GelBoxLayout,
    GelLink,
    GelRowLayout,
    GelRow
  } from '@tal-gel/components';
import { Brands } from "../Shared";
import FooterLinks from '../Shared/FooterLinks';
import helpers from '../Shared/helpers';


const Footer = ({brand, width}) => {
const footerTextStyle = {
    textAlign: 'center',
  };
const [displayText] = useState(helpers.SetCurrentBrandText(brand));

width = helpers.GetComponentScreenWidth();
  return (
    <GelContainerLite distribution="center" gutter="medium">
      <GelRow justify="center" align="center">
        <GelRowLayout  width={width} gutter="medium">                      
            <FooterLinks brand={brand}></FooterLinks>        
            <GelParagraph  small style ={footerTextStyle}>
            {new Date().getFullYear()} &copy;  {displayText.footer} 
            </GelParagraph>
        </GelRowLayout>
     </GelRow>
  </GelContainerLite> 
  );
};

export default Footer;