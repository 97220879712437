import { configureTheming } from '@tal-gel/theming';
import ThemingPluginTal from '@tal-gel/theming-plugin-tal';
import ThemingPluginIl from '@tal-gel/theming-plugin-il-2022';
import ThemingPluginAstn from '@tal-gel/theming-plugin-asteron';
import ThemingPluginVma from '@tal-gel/theming-plugin-vma';
import ThemingPluginNib from '@tal-gel/theming-plugin-nib';
import ThemingPluginSun from '@tal-gel/theming-plugin-suncorp';
import ThemingPluginGio from '@tal-gel/theming-plugin-gio';
import ThemingPluginApia from '@tal-gel/theming-plugin-apia';
import ThemingPluginAami from '@tal-gel/theming-plugin-aami';
import ThemingPluginNrma from '@tal-gel/theming-plugin-nrma';

configureTheming({
  plugins: [
    ThemingPluginTal, // Add TAL theme to tal-gel
    ThemingPluginIl, // Add InsuranceLine theme to tal-gel
    ThemingPluginAstn, // Add Asteron theme to tal-gel
    ThemingPluginVma, // Add Virgin Money Australia theme to tal-gel
    ThemingPluginNib, // Add NIB theme to tal-gel
    ThemingPluginSun, // Add Suncorp theme to tal-gel
    ThemingPluginGio, //Add GIO theme to tal-gel
    ThemingPluginApia, // Add APIA theme to tal-gel
    ThemingPluginAami, // Add AAMI theme to tal-gel
    ThemingPluginNrma // Add NRMA theme to tal-gel
  ]
});