import envConfig from '../env.config';
import helpers from '../components/Shared/helpers';

export default async function sendAdobeTag({ brand, pageEventName, formStep}) {
    try {        
        window.appEventData.push({
            "event": pageEventName,
            "page": {
            "pageInfo": {
                    "brand": brand === "" ? "Payments" : `${brand.toUpperCase()} Payments`, //Change TAL prefix to relevant Brand i.e IL
                    "pageName": "Make a payment", //eg.: tal: make a payment
                    "formName": helpers.IsInternalUrl() ? "Internal" : "External",
                    "formStep": formStep, //eg.: Payment Home
                    "environment": envConfig.ENVIRONMENT_NAME
                }
            }
        });
    } catch(err) {
        console.log(err);
        if(window.appEventData === undefined) {
            await new Promise(resolve => setTimeout(resolve, 100));
            await sendAdobeTag({brand: brand, pageEventName:pageEventName, formStep:formStep});
        }
    }
}