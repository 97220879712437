export const Brands = {
    TAL: "tal",
    IL: "il",
    ASTERON: 'astn',
    VMA: 'vma',
    NIB: 'nib',
    SUNCORP: 'sun',
    GIO:'gio',
    APIA: 'apa',
    AAMI: 'aam',
    NRMA: 'nrm'
};

export const TxnStatus = {
    SUCCESSFUL: "Successful",
    UNSUCCESSFUL: "Unsuccessful",
    FAILED: 'Failed'
};

export const BussinessCode = {
    DIRECT: 'Direct',
    RETAIL: 'Retail'
}