const processReceipt = `query correspondence($correspondenceInput: correspondenceInput) {
        sendCorrespondence(correspondenceInput: $correspondenceInput ) {
            isSent
            authToken
            isSentMaxNoOfTimes
        }
    }`;

export {
    processReceipt
};
