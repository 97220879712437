import React from "react";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";

const CustomContainer = ({
  children,
  mt,
  mb,
  mr,
  ml,
  pt,
  pb,
  pl,
  pr,
  padding,
  width,
  border,
  borderRadius,
}) => {
  return (
    <StyledContainer
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      padding={padding}
      width={width}
      border={border}
      borderRadius={borderRadius}
    >
      {children}
    </StyledContainer>
  );
};

export default CustomContainer;

const StyledContainer = styled.div(
  {
    backgroundColor: getGelTokens().global.themeColorBackgroundDefault,
  },

  (props) => ({
    marginTop: props.mt,
    marginBottom: props.mb,
    marginRight: props.mr,
    marginLeft: props.ml,
    paddingTop: props.pt,
    paddingBottom: props.pb,
    paddingLeft: props.pl,
    paddingRight: props.pr,
    padding: props.padding,
    width: props.width,
    border: props.border,
    borderRadius: props.borderRadius,
  })
);
