import { TextConfig } from "./TextConfig";

export class TALText extends TextConfig {
    constructor() {
        super()
        this.brand = 'TAL';
        this.title = 'TAL';
        this.phoneNumber = "1300 209 088";
        this.privacyPolicy = 'https://www.tal.com.au/privacy-policy';
        this.terms = 'TAL Life Limited';
        this.pleaseNote = '1300 348 011 (Mon-Fri 8am-5pm AET)';
        this.footer = 'TAL Services Limited a Dai-ichi Life Company.';
        this.aboutUs = 'https://www.tal.com.au/about-us';
        this.security = 'https://www.tal.com.au/security';
        this.complaints = 'https://www.tal.com.au/contact-us/complaint-handling-process';
        this.disclaimer = 'https://www.tal.com.au/disclaimer';
        this.superContact = '1300 209 088 (Mon-Fri 9am-5pm AET)';
    };
}