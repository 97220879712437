import React, { useContext, useEffect, useState } from "react";
import {
    GelAccordion,
    GelBoxLayout, GelButton, GelRadioButtonGroup,
    GelRadio, GelHeading4,
    GelCaption, GelForm, GelFormField,
    GelHeading6, GelIcon, GelLabel,
    GelParagraph, GelContainerLite, GelTextInput,
    useGelFormData, GelSpinner, GelRowLayout,
    GelList, GelListItem
} from "@tal-gel/components";

import { getGelTokens } from "@tal-gel/theming";
import { headingStyle } from '../Shared';
import CustomContainer from '../Shared/CustomContainer'
import Divider from '../Shared/Divider'
import { GetAuthKeyQuery } from '../../services/unAuthenticatedPaymentService';
import { getAuthKey } from '../../services/unAuthenticPaymentQueries/getAuthKey';
import envConfig from "../../env.config";
import helpers from '../Shared/helpers';
import sendAdobeTag from '../../services/sendAdobeTag';

const ConfirmPaymentDetails = React.memo(({ width }) => {
    const {
        formData, // form data of this step
        onFormDataChange,
        onPrevStep, // goes back to the previous step
        onNextStep,
        multiStepFormData, // overall form data of all steps
        onFormFieldChange
    } = useGelFormData(
        {
            // initial data of this step
            amount: '',
            selectedAmount: '',
            customAmount: ''
        },
        // Step name, note that `formData` is to be scoped
        // to this name in the overall multi step form data
        // object, Eg: { step2: formData }
        "ConfirmPaymentDetails"
    );
    const [loading, setLoading] = useState();
    const [isInternal, setIsInternal] = useState(helpers.IsInternalUrl());
    const currentBrand = helpers.GetCurrentBrand();
    const [displayText] = useState(helpers.SetCurrentBrandText(currentBrand, multiStepFormData.policyNumber.businessArea, multiStepFormData.policyNumber.sourceSystemCode));

    const getTotalAmount = () => {
        let total = (Number)(multiStepFormData.policyNumber.amount) + (Number)(multiStepFormData.policyNumber.overdueAmount);
        return total.toFixed(2);
    }

    const displayAllPremiums = () => {
        let unacceptableSourceSystemCodes = ['LFH'];
        return !unacceptableSourceSystemCodes.includes(multiStepFormData.policyNumber.sourceSystemCode);
    }

    //Set default value based on overdue
    useEffect(() => {
        // Send data to marketing team
        sendAdobeTag({ brand:multiStepFormData.policyNumber.brandCode, pageEventName:"Form Step", formStep: "Pay Amount" });

        formData.authKey = '';
        if (formData.selectedAmount === '')
            if (!displayAllPremiums())
                formData.amount = multiStepFormData.policyNumber.isOverdue ? '1' : '4';
            else
                formData.amount = multiStepFormData.policyNumber.isOverdue ? '1' : '2';
    }, []);

    width = helpers.GetComponentScreenWidth();

    const clearNextComponentData = () => {
        if(multiStepFormData.PaymentDetails) {
            multiStepFormData.PaymentDetails.nameOnCardField = '';
            multiStepFormData.PaymentDetails.cardNumberField ='';
            multiStepFormData.PaymentDetails.expiryDate = '';
            multiStepFormData.PaymentDetails.cvv ='';
            multiStepFormData.PaymentDetails.terms = '';
        }
    }

    const onSubmit = () => {

        if (formData.amount === "1")
            formData.selectedAmount = multiStepFormData.policyNumber.overdueAmount;
        else if (formData.amount === "2")
            formData.selectedAmount = multiStepFormData.policyNumber.amount;
        else if (formData.amount === "4")
            formData.selectedAmount = formData.customAmount;
        else
            formData.selectedAmount = getTotalAmount();

        //Timeout is configurable and it is set to 30 secs by default
        const timer = setTimeout(() => {
            onNextStep();
        }, envConfig.BPOINT_TIMEOUT);

        //Call tries
        var retriesOnTimeout = Number(envConfig.BPOINT_TRIES);

        //call payment api to get authkey with selected amount
        const callBpointApi = async () => {
            retriesOnTimeout--;
            setLoading(true);
            const response = await GetAuthKeyQuery({
                query: getAuthKey, authKeyInput: { 
                    "policyNumber":  multiStepFormData.policyNumber.policyNo,
                    "amount": formData.selectedAmount,
                    "merchantId": multiStepFormData.policyNumber.merchantId,
                    "authToken": helpers.GetAuthTokenCookies()
                }
            });
            if (response.error) {
                if (retriesOnTimeout > 0)
                    callBpointApi();
                else
                    //navigate to next page when api call is unsuccessfull/error
                    onNextStep();
            }
            else {
                if (response.data.authKey) {
                    if (response.data.authKey.authKey) {
                        //set authkey
                        onFormFieldChange({
                            add: {
                                authKey: response.data.authKey.authKey,
                            },
                        });
                        helpers.SetAuthCookies(response.data.authKey.authToken);
                        clearNextComponentData();
                        onNextStep();
                    }
                    else {
                        //navigate to next page when there is no auth key
                        onNextStep();
                    }
                }
                else {
                    if (retriesOnTimeout > 0)
                        callBpointApi();
                    else {
                        //navigate to next page when there is no auth key
                        onNextStep();
                    }
                }
            }
        };

        callBpointApi();
        return false;
    }

    return (<>
        <CustomContainer
            width={width}
            mt={getGelTokens().global.sizeBaseX6}            
            ml='auto'
            mr='auto'
            padding={`${getGelTokens().global.sizeBaseX5}px ${getGelTokens().global.sizeBaseX8}px`}
        >
            {loading && <GelSpinner large overlay></GelSpinner>}
            <GelRowLayout gutter="medium">
                <GelBoxLayout distribution="spaceBetween" space={'auto'}>
                    <GelParagraph>Policy number</GelParagraph>
                    <GelLabel>{multiStepFormData.policyNumber.policyNo}</GelLabel>
                </GelBoxLayout>
                <GelBoxLayout style ={{ "visibility" : isInternal ? "visible" : "hidden"}}  disabled={false} distribution="spaceBetween" space={'auto'}>
                    <GelParagraph>Brand</GelParagraph>
                    <GelLabel>{multiStepFormData.policyNumber.brandName}</GelLabel>
                </GelBoxLayout>
            </GelRowLayout>
        </CustomContainer>
        <CustomContainer
            width={width}
            mt={getGelTokens().global.sizeBaseX6}
            mb={getGelTokens().global.sizeBaseX6}
            ml='auto'
            mr='auto'
            padding={getGelTokens().global.sizeBaseX8}
            borderRadius={getGelTokens().global.sizeBaseHalf}
            backgroundColor={getGelTokens().global.themeColorBackgroundDefault}
        >
            <GelForm
                labelAtTop
                disableOnSubmit
                onSubmit={onSubmit}
                parseResponseBody={false} // Remove this if you use `fetch` in `onSubmit`
                fieldGutter={getGelTokens().global.sizeBaseX8}
            >
                <GelHeading6 as="h2" style={headingStyle}>
                    Premium payment amount:
                </GelHeading6>
                <GelFormField>
                    <GelRadioButtonGroup
                        name="amount"
                        vertical
                        value={formData.amount}
                        onChange={onFormDataChange}
                    >
                        {multiStepFormData.policyNumber.isOverdue && <GelRadio value={1}>
                            <GelBoxLayout space="auto">
                                <span>
                                    Overdue amount
                                    <GelIcon
                                        name={isInternal ? "AlertCircle" : ""}
                                        inline
                                        width={getGelTokens().global.sizeBaseX5}
                                        color={getGelTokens().global.themeColorIconDanger}
                                    ></GelIcon>
                                </span>
                            </GelBoxLayout>
                            {formData.amount === "1" && (
                                <GelHeading4 as="span" large>
                                    ${multiStepFormData.policyNumber.overdueAmount}
                                </GelHeading4>
                            )}
                            {formData.amount !== "1" && <GelParagraph>{multiStepFormData.policyNumber.overdueAmount}</GelParagraph>}
                        </GelRadio>}

                        {isInternal && displayAllPremiums() && <GelRadio value={2}>
                            Regular premium
                            {formData.amount !== "2" && <GelParagraph>{multiStepFormData.policyNumber.amount}</GelParagraph>}
                            {formData.amount === "2" && (
                                <GelHeading4 as="span" large>
                                    ${multiStepFormData.policyNumber.amount}
                                </GelHeading4>
                            )}
                        </GelRadio>}

                        {isInternal && displayAllPremiums() && multiStepFormData.policyNumber.isOverdue && <GelRadio value={3}>
                            Overdue amount + Regular premium
                            {formData.amount !== "3" && <GelParagraph> {getTotalAmount()}</GelParagraph>}
                            {formData.amount === "3" && (
                                <GelHeading4 as="span" large>
                                    ${getTotalAmount()}
                                </GelHeading4>
                            )}
                        </GelRadio>}

                        {isInternal && <GelRadio value={4}>
                            Custom amount
                        </GelRadio>}
                        {formData.amount === "4" && (
                            <GelContainerLite
                                gutter="medium"
                                style={{ backgroundColor: getGelTokens().global.themeColorBackgroundLight }}
                            >
                                <GelFormField label="Enter amount">
                                    <GelTextInput
                                        prefix="$"
                                        name="customAmount"
                                        value={formData.customAmount}
                                        onChange={onFormDataChange}
                                        required
                                        regex={/^[0-9.]+$/i}
                                        errorMsg={{
                                            required: "Please enter an amount",
                                            regex: "Please enter a valid amount",
                                        }}
                                    ></GelTextInput>
                                </GelFormField>
                            </GelContainerLite>
                        )}
                    </GelRadioButtonGroup>
                </GelFormField>
                <Divider mt={32} mb={32}></Divider>
                <GelAccordion title="Important information" 
                    minContentHeight={0}
                    defaultExpand={true}>
                <strong>Amounts displayed</strong>
                <GelParagraph style={{ marginBottom: getGelTokens().global.sizeBaseX3 }}>
                    Amounts for payment displayed reflect the premium 
                    amount currently overdue in respect of your policy. 
                    For the premium that will apply from the next policy anniversary, 
                    please refer to your premium notice or contact us on {displayText.pleaseNote}.
                </GelParagraph>
                <strong>Joint Account</strong>
                <GelParagraph style={{ marginBottom: getGelTokens().global.sizeBaseX3 }}>
                    Payments must be made from your own account. 
                    To make a payment from a joint account, you must have appropriate authority from all account holders. 
                </GelParagraph>
                <strong>Superannuation payments </strong>
                <GelParagraph style={{ marginBottom: getGelTokens().global.sizeBaseX3 }}>
                    Payments into superannuation are subject to superannuation and tax law 
                    (such as superannuation contribution rules and limits). 
                    We recommend you obtain professional financial and tax advice that is specific to your financial circumstances, 
                    situation and needs before deciding to make any payment and the manner and source of payment. 
                    TAL accepts no liability or responsibility for these matters which are 
                    the responsibility of the trustee of the superannuation fund. 
                </GelParagraph>
                </GelAccordion>
                <GelBoxLayout
                    distribution="spaceBetween"
                    space={"0 auto"}
                    style={{ marginTop: getGelTokens().global.sizeBaseX8 }}
                >
                    <GelFormField>
                        <GelButton
                            name="" // Makes it a managed component
                            secondary
                            medium
                            // Goes back to the previous step
                            onClick={onPrevStep}
                        >
                            Back
                        </GelButton>
                    </GelFormField>

                    <GelFormField>
                        <GelButton
                            name="" // Makes it a managed component
                            primary
                            medium
                            submit
                        >
                            Continue
                        </GelButton>
                    </GelFormField>
                </GelBoxLayout>
            </GelForm>
        </CustomContainer>
    </>
    );
});

export default ConfirmPaymentDetails;