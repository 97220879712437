import React,{ useEffect } from 'react';
import {
  GelPageLayout,
  GelPageHeader,
  GelPageContent,
  GelPageFooter,
  GelHeading6,
  GelParagraph,
  GelLink,
} from '@tal-gel/components';
import { GelThemeProvider, getGelTokens } from '@tal-gel/theming';

import helpers from '../components/Shared/helpers';
import CustomContainer from '../components/Shared/CustomContainer';

import {
  GelScreenDetector
} from '@tal-gel/components';

import Navbar from "../components/UnauthenticatedPayments/Navbar";
import Footer from '../components/UnauthenticatedPayments/Footer';


const MaintenancePage = () => { 

  useEffect(() => {    
    helpers.SetPageTitle(helpers.GetCurrentBrand(), 'Maintenance');
    helpers.SetFavicon(helpers.GetCurrentBrand(false));    
  },[]); 

  const displayText = helpers.SetCurrentBrandText(helpers.GetCurrentBrand());

  return (<GelThemeProvider theme={helpers.GetBrandTheme(helpers.GetCurrentBrand())} mode={process.env.NODE_ENV}> 
    <GelScreenDetector>
    <GelPageLayout>
          <GelPageHeader>
            <Navbar brand={helpers.GetCurrentBrand()} />
          </GelPageHeader>       
          <GelPageContent fixed style={{ backgroundColor: getGelTokens().global.themeColorGrayT05 }}>             
             <CustomContainer
                width="60%"
                mt={getGelTokens().global.sizeBaseX6}
                mb={getGelTokens().global.sizeBaseX6}
                ml='auto'
                mr='auto'
                padding={`${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX8}px`}
                borderRadius={2}
                backgroundColor={getGelTokens().global.themeColorBackgroundDefault}>              
                <GelHeading6 as="h2">
                    This page is currently undergoing maintenance.
                </GelHeading6>
                <br/>
                <GelHeading6 as="h2">
                    We apologise for the inconvenience.
                </GelHeading6>
                {displayText.phoneNumber && <GelParagraph style={{ marginBottom: getGelTokens().global.sizeBaseX8 }}>
                    If you have any questions or require assistance, please do not hesitate to call us on <GelLink style={{ whiteSpace: "nowrap", }} href={`tel: ${displayText.phoneNumber.replace(/\s+/g, "")}`}>{ displayText.phoneNumber}</GelLink>.
                </GelParagraph>}
            </CustomContainer>
           </GelPageContent>
          <GelPageFooter fullWidth={false}>
            <Footer brand={helpers.GetCurrentBrand()}></Footer>
          </GelPageFooter>
        </GelPageLayout>
      </GelScreenDetector>
    </GelThemeProvider>    
  );
}

export default MaintenancePage;