import { TextConfig } from "./TextConfig";

export class VirginMoneyAustraliaText extends TextConfig {
    constructor() {
        super()
        this.brand = 'Virgin Money (Australia)';
        this.title = 'VIRGIN MONEY (AUSTRALIA)';
        this.phoneNumber = "1300 849 965";
        this.parentBrand ='TAL';
        this.privacyPolicy = 'https://virginmoney.com.au/help/privacy-and-security';
        this.parentBranchPrivacyPolicy='https://www.tal.com.au/privacy-policy';
        this.terms = 'Virgin Money (Australia) Pty Limited';
        this.pleaseNote = '1300 849 965, 8am - 7pm AEST Monday to Friday';
        this.footer = 'Virgin Money (Australia) Pty Limited ABN 75 103 478 897';
        this.aboutUs = 'https://virginmoney.com.au/about-us';
        this.security = 'https://virginmoney.com.au/help/privacy-and-security';
        this.complaints = 'https://virginmoney.com.au/insurance/life-insurance/contact-us';
        this.disclaimer = 'https://virginmoney.com.au/insurance/life-insurance';
        this.contactTime = '8am-8pm AEST, Monday-Friday';
        this.contactTimeFormat=', 8am - 7pm AEST Monday to Friday';
        this.disclaimerBrand = 'Virgin Money (Australia) Pty Limited';
    };
}