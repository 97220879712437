import React from "react";
import { getGelTokens } from '@tal-gel/theming';
import { 
  GelContainerLite, GelRowLayout, GelBoxLayout, 
  GelHeading6, GelParagraph 
} from '@tal-gel/components';

import DirectDebit from './DirectDebit';
import OneOff from './OneOff';
import { whiteBackgroundStyle, Brands } from '../Shared';

const PaymentOptions = React.memo(({ 
  brand, 
  policyNumber,
  directDebitDetail, 
  bPayDetail,
  premiumOverdue,
  showOneOff
}) => {
  const isTalBrand = () => brand === Brands.TAL;

  const getPaymentOptionsContent = () => {
    if (!isTalBrand()) {
      return `Payments must be made from your own account.
      If you want to make a payment from a joint account, 
      you must have appropriate authority from all account holders.`;
    } 
    return `Payments must be made from your own account.
      If you want to make a payment from a joint account, 
      you must have appropriate authority from all account holders. 
      For policies issued through TAL Super and Insurance Fund, 
      MyTAL can only be used to make personal contributions. 
      Employer contributions can only be made through SuperStream.`;
  };
  const paymentOptionsContent = getPaymentOptionsContent();

  return (
    <GelContainerLite 
      data-testid="payment-options-section"
      gutter={{ 
        left: ((isTalBrand() && getGelTokens().global.sizeBaseX2) || getGelTokens().global.sizeBaseX5), 
        right: ((isTalBrand() && getGelTokens().global.sizeBaseX2) || getGelTokens().global.sizeBaseX5),
        top: !isTalBrand() && getGelTokens().global.sizeBaseX12, 
        bottom: getGelTokens().global.sizeBaseX15 
      }}
      style={whiteBackgroundStyle}
    >
      <GelRowLayout gutter="medium">
        <GelHeading6 as="h2">Payment Options</GelHeading6>

        <GelRowLayout gutter="xlarge">
          <GelParagraph>
            {paymentOptionsContent}
          </GelParagraph>

          <GelBoxLayout alignment="start" gap="xlarge">
            <DirectDebit 
              policyNumber={policyNumber}
              directDebitDetail={directDebitDetail}
            />
            { showOneOff &&
              <OneOff 
                brand={brand}
                policyNumber={policyNumber}
                bPayDetail={bPayDetail} 
                premiumOverdue={premiumOverdue}
              />
            }
          </GelBoxLayout>
        </GelRowLayout>
      </GelRowLayout> 
    </GelContainerLite>
  );
});

export default PaymentOptions;
