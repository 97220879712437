import React, { useContext } from "react";
import { 
  GelContainerLite, GelRowLayout,
  GelScreenDetectorContext,
  GelTabs, GelTab
} from '@tal-gel/components';

import { whiteBackgroundStyle, Text } from '../Shared';
import PaymentOptions from '../../components/PaymentOptions/PaymentOptions';
import PaymentHistory from '../../components/PaymentHistory/PaymentHistory';

const PaymentTabs = React.memo(({ brand, 
    selectedPolicy,
    paymentHistoryList,
    policyNumber,
    directDebitDetail, 
    bPayDetail,
    premiumOverdue,
    showOneOff }) => {
      
  return (<>
      <GelContainerLite 
       data-testid="payment-tab-section"
        gutter={{
            horizontal: 0, 
            vertical: 'xlarge' 
        }}
        style={whiteBackgroundStyle}
    >
        <GelTabs condensed>
          <GelTab title="Payment details">
            <GelRowLayout>
            { !selectedPolicy.isPlatformPolicy && <>
              <PaymentOptions
                brand={brand}
                policyNumber={policyNumber}
                directDebitDetail={directDebitDetail}
                bPayDetail={bPayDetail} 
                premiumOverdue ={premiumOverdue}
                showOneOff={showOneOff}
              />
              </> }
            </GelRowLayout>
          </GelTab>

          <GelTab title="Payment history">
            <GelRowLayout>
            { paymentHistoryList.isHistoryReturned  && <>
            <PaymentHistory
                brand={brand}
                paymentHistoryList={paymentHistoryList.historyList}
                policyNumber={policyNumber}
                initialLoading ={false}
                isHistoryReturned ={true}
            />
            </> }
            { !paymentHistoryList.isHistoryReturned  && <>
                <Text>We can’t find any payments for this policy. If this is incorrect please call 1300 209 088</Text>
            </> }
            </GelRowLayout>
          </GelTab>

        </GelTabs>
        </GelContainerLite>
  </>);
});

export default PaymentTabs;
