import React from "react";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";

const Divider = ({
    children,
    mt,
    mb,
    mr,
    ml,
    width,
    border
}) => {
    return (
        <StyledDivider
            mt={mt}
            mb={mb}
            mr={mr}
            ml={ml}
            width={width}
            borderColor={border}
        >
            {children}
        </StyledDivider>
    );
};

export default Divider;

const StyledDivider = styled.hr(
    {
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: getGelTokens().global.themeColorBorderDefault,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
    },

    (props) => ({
        marginTop: props.mt,
        marginBottom: props.mb,
        marginRight: props.mr,
        marginLeft: props.ml,
        width: props.width,
        borderColor: props.borderColor,
    })
);
