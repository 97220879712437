import React,{useContext, useEffect, useState, Button} from 'react';
import {
  GelPageLayout,
  GelPageHeader,
  GelPageContent,
  GelPageFooter,
  GelContainerLite,
  GelPageContentContainer,
  GelParagraph,
  GelBoxLayout,
  GelMultiStepForm,
  GelLink,
  GelGap,GelButton,
  GelScreenDetectorContext
} from '@tal-gel/components';
import { GelThemeProvider, getGelTokens } from '@tal-gel/theming';
import { Text } from '../components/Shared';

import helpers from '../components/Shared/helpers';

import {
  GelScreenDetector, GelRowLayout,
  GelHeading3
} from '@tal-gel/components';

import { borderStyle } from '../components/Shared';
import PolicyNumber from '../components/UnauthenticatedPayments/PolicyNumber';
import PaymentDetails from '../components/UnauthenticatedPayments/PaymentDetails';
import ConfirmPolicyDetails from '../components/UnauthenticatedPayments/ConfirmPolicyDetails';
import SuccessOrErrorDetails from '../components/UnauthenticatedPayments/SuccessOrErrorPayment';
import useScript from '../components/Shared/useScript';
import env from '../env.config';
import Navbar from "../components/UnauthenticatedPayments/Navbar";
import { Brands } from '../components/Shared';
import Footer from '../components/UnauthenticatedPayments/Footer';
import { useMsal, useIsAuthenticated  } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";

const UnauthenticatedPaymentsPage = () => {
  const [isInternal] = useState(helpers.IsInternalUrl());
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isAdobeScriptLoaded, setIsAdobeScriptLoaded] = useState(false);

  const adobeScriptUrls = JSON.parse(env.ADOBE_SCRIPT_URLS || '{}');

  const OnAdobeScriptLoaded = () => {
    setIsAdobeScriptLoaded(true); 
  }; 

  useEffect(() => {
  //Append Adobe tag in the header
    const selectedAdobeScriptUrl = adobeScriptUrls[GetCurrentBrand] || adobeScriptUrls.default;
    helpers.AppendScriptToHead(selectedAdobeScriptUrl, OnAdobeScriptLoaded);
    helpers.SetPageTitle(helpers.GetCurrentBrand(), 'Make a Payment');
    helpers.SetFavicon(helpers.GetCurrentBrand(false));
  },[]);  

  const BPOINT = useScript(env.BPOINT_URL); 

  useEffect(() => {    
    if(isInternal && !isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect({
        scopes: ['User.Read']
      });    
    }
  },[isAuthenticated, inProgress, instance]);  

  /**
   * Gets the current brand from the url
   * If empty, defaults to TAL brand
   */
  
  const GetCurrentBrand = helpers.GetCurrentBrand();

  return (<GelThemeProvider theme={helpers.GetBrandTheme(GetCurrentBrand)} mode={process.env.NODE_ENV}> 
    <GelScreenDetector>
    {(!isInternal || isAuthenticated) && (  <GelPageLayout>
          <GelPageHeader>
            <Navbar brand={GetCurrentBrand} />
          </GelPageHeader>
          <GelPageContent fixed>
            <GelHeading3 as="h1" data-testid="paymentheader"
              marginTop={getGelTokens().global.sizeBaseX4}
              marginBottom={getGelTokens().global.sizeBaseX4} >
              Make a payment
            </GelHeading3>
          </GelPageContent>
          <GelPageContent fixed style={{
              backgroundColor: getGelTokens().global.themeColorGrayT05,
            }}>
              <GelBoxLayout>
              <GelMultiStepForm render={currentStep => (
                <>
                  {currentStep === 1 && (<PolicyNumber width="60%" isAdobeScriptLoaded={isAdobeScriptLoaded} />)}
                  {currentStep === 2 && (<ConfirmPolicyDetails width="60%" />)}
                  {currentStep === 3 && (<PaymentDetails width='60%' />)}
                  {currentStep === 4 && (<SuccessOrErrorDetails width='60%' />)}
                </>
              )} />
            </GelBoxLayout>
          </GelPageContent>
          <GelPageFooter fullWidth={false}>
            <Footer brand={GetCurrentBrand}></Footer>
          </GelPageFooter>
        </GelPageLayout>)}
      </GelScreenDetector>
    </GelThemeProvider>    
  );
}

export default UnauthenticatedPaymentsPage;