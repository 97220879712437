import 'isomorphic-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

import './setupTalGel';
import PaymentsPage from './pages/PaymentsPage';
import * as serviceWorker from './serviceWorker';
import UnauthenticatedPaymentsPage from './pages/UnauthenticatedPaymentsPage';
import { PublicClientApplication, EventType, LogLevel } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import env from './env.config';
import helpers from './components/Shared/helpers';
import MaintenancePage from './pages/MaintenancePage';
import NotFoundPage from './pages/NotFoundPage'

const pca = new PublicClientApplication({
  auth: {
    clientId: env.AAD_CLIENTID,
    authority: env.AAD_AUTHORITY,
    redirectUri: '/tal'
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false, 
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {	
        if (containsPii) {		
            return;		
        }		
        switch (level) {
            case LogLevel.Error:
                console.error(message);
                return;
            case LogLevel.Info:
                console.info(message);
                return;
            case LogLevel.Verbose:
                console.debug(message);
                return;
            case LogLevel.Warning:
                console.warn(message);
                return;
            default:
                return;
        }	
      }
    }
  }
});

pca.addEventCallback((event) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) && event.payload.account) {    
    const account = event.payload.account;
    pca.setActiveAccount(account);
  }}, error=>{
    console.log('error', error);
  }
);

const isInternalNonTal = () => {
  //For internal url, if brand is not tal, redirect to tal brand
    if(helpers.IsInternalUrl()) {
      if(helpers.GetCurrentBrand() !== 'tal') {
        window.location.replace(window.location.origin + "/tal");
        return true;
      }
    }
    return false;
  };

  // Check whether request is coming from ECSS website or payments portal. 'Access' used to filter out IL portal as cloaking of IL
  // branding will contain 'insuranceline'.
  const isECSSRequest = () => {
    return window.location.hostname.includes('mytal') || window.location.hostname.includes('access') || window.location.hostname.includes('ecss');
  };

  // Route to maintenance page when the Frond door is pointing to the maintenance.html instead of the index.html
  const isMaintenancePage = () => {
    const element = window.parent.document.getElementById("maintenancePage");
    return element != null ? true : false;
  }

ReactDOM.render(
  <React.StrictMode>
    { // The following hidden element will be provided by Sitecore
      // outside this React app in production
      // to differentiate the brand in which this project is called.

      // As `ecss-payments-ui` is a standalone React project, 
      // we put this hidden element here for testing purposes in development.
      process.env.NODE_ENV === 'development' &&
      <input hidden id="currentBrand" defaultValue="tal" />
      
    }    

    <Router>
      <div>
       {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          {isMaintenancePage() && <Route path="*" component={MaintenancePage} />}

          {isECSSRequest() && <Route path="*" component={PaymentsPage}/> }
          
          {isInternalNonTal()}

          <Route path={"/(" + env.ACTIVE_BRANDS + ")/"}>
            <MsalProvider instance={pca}>
              <UnauthenticatedPaymentsPage />
            </MsalProvider>
          </Route>         

          <Route path="*" component={NotFoundPage} />

        </Switch>
      </div>
    </Router> 


  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();