import React from "react";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";

const StyledNavbarContainer = styled.div`
  background-colour: ${getGelTokens().global.themeColorBackgroundDefault};
  padding-left: ${getGelTokens().global.sizeBaseX4}px;
  padding-right: ${getGelTokens().global.sizeBaseX4}px;
  display: flex;
  align-items: center;
  align-content: center;
  height: ${getGelTokens().global.sizeBaseX14}px;
  border-bottom: 1px solid ${getGelTokens().global.themeColorBorderDefault};
`;

export default StyledNavbarContainer;