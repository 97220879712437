import React, { useState } from "react";
import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";
import helpers from '../Shared/helpers';
import logoConfig from '../Shared/Logo';
import StyledNavbarContainer from '../Shared/StyledNavbarContainer';

const Navbar = ({ brand }) => {
  const [logo] = useState(logoConfig.getLogoPathBasedonBrand(brand));
  const navBarStyle = {height: logo.SetNavbarHeight()};

  return (
    <StyledNavbarContainer style={navBarStyle}>
      {logo.SetLogo()}
    </StyledNavbarContainer>
  );
};

export default Navbar;
