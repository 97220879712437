import envConfig from '../env.config';
import moment from 'moment';
import helpers from '../components/Shared/helpers';

export async function unAuthenticateQuery({ bearerToken, query, policyDetailInput }) {
  var policyEnpoint = 'policy/';
  var apiUrl = envConfig.API_URL;
  if(helpers.IsInternalUrl()){
    policyEnpoint = 'internalPolicy/';
    apiUrl = envConfig.API_INTERNAL_URL;
  }

  let data, error;
  try {
    const response = await fetch(apiUrl + policyEnpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Ocp-Apim-Subscription-Key': envConfig.API_KEY,
        'cache-control': 'no-cache',
        'pragma': 'no-cache',
        'Authorization': 'Bearer ' + bearerToken
      },
      body: JSON.stringify({
        query: query,
        variables: {
          policyDetailInput: policyDetailInput
        }
      })
    });
    if (response.ok) {
      data = await response.json();
    } else {
        throw new Error(response.status);
    }
  }
  catch (err) {
    error = err;
  }
  return { data, error };
}

export async function GetAuthKeyQuery({ query, authKeyInput }) {

  let data, error;
  try {
    const response = await fetch(envConfig.API_URL + 'bpointTransaction/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Ocp-Apim-Subscription-Key': envConfig.API_KEY,
        'cache-control': 'no-cache',
        'pragma': 'no-cache'
      },
      body: JSON.stringify({
        query: query,
        variables: {
          authKeyInput: authKeyInput
        }
      })
    });
    if (response.ok) {
      data = await response.json();
    } else {
      throw new Error(response.statusText);
    }
  }
  catch (err) {
    error = err;
  }
  return { data, error };
}

export async function ProcessTransactionQuery({ query, processTransactionInput }) {
  let data, error;
  try {
    const response = await fetch(envConfig.API_URL + 'bpointTransaction/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Ocp-Apim-Subscription-Key': envConfig.API_KEY,
        'cache-control': 'no-cache',
        'pragma': 'no-cache'
      },
      body: JSON.stringify({
        query: query,
        variables: { processTransactionInput: processTransactionInput }
      })
    });
    if (response.ok) {
      data = await response.json();
    } else {
      throw new Error(response.statusText);
    }
  }
  catch (err) {
    error = err;
  }
  return { data, error };
}

export async function ProcessReceiptRequest({ query, correspondenceInput }) {
  let data, error;
  Date.prototype.toJSON = function(){ return moment(this).format(); }
  try {
    const response = await fetch(envConfig.API_URL + 'SendCorrespondence', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Ocp-Apim-Subscription-Key': envConfig.API_KEY,
        'cache-control': 'no-cache',
        'pragma': 'no-cache'
      },
      body: JSON.stringify({
        query: query,
        variables: { correspondenceInput: correspondenceInput }
      })
    });
    if (response.ok) {
      data = await response.json();
    } else {
      throw new Error(response.statusText);
    }
  }
  catch (err) {
    error = err;
  }
  return { data, error };
}





