import React from "react";
import { 
  GelBoxLayout, GelRowLayout, 
  GelButton, GelList, GelListItem, 
  GelHeading6, GelParagraph 
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';

import CreditCard from './CreditCard';
import { Text, h3FontSizeStyle, h4FontSizeStyle, Brands } from '../Shared';

const OneOff = React.memo(({ brand, policyNumber, bPayDetail = {} , premiumOverdue={}}) => {
  const onPayNowClick = () => {
    window.open(
      `/dashboard/policy-details/make-a-payment/pay-via-credit-card?policyId=${policyNumber}`,
      '_self'
    );
  };
  
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }

  const isTalBrand = () => brand === Brands.TAL;

  if (isTalBrand() && !isEmpty(bPayDetail)) {
    return (
      <GelRowLayout gutter={getGelTokens().global.sizeBaseX5}>
        <GelHeading6 as="h3" style={h3FontSizeStyle}>
          Make a one off payment
        </GelHeading6>
        <GelParagraph>
          At TAL we offer two ways of making a one off payment:
        </GelParagraph>

        <GelList ordered dot alignment="baseline" gap="large">
          <GelListItem>
            <CreditCard policyNumber={policyNumber} premiumOverdue={premiumOverdue} />
          </GelListItem>

          <GelListItem>
            <GelRowLayout gutter="medium">
              <GelHeading6 as="h4" style={h4FontSizeStyle}>
                BPAY
              </GelHeading6>

              <GelBoxLayout>
                <GelRowLayout gutter="small">
                  <Text bold>Biller Code</Text>
                  <Text>{ bPayDetail.routingNumberId }</Text>
                </GelRowLayout>
                <GelRowLayout gutter="small">
                  <Text bold>Reference Number</Text>
                  <Text>{ bPayDetail.accountNumberId }</Text>
                </GelRowLayout>
              </GelBoxLayout>

              <GelParagraph>
                Please note: BPAY payments can take up to 3 days to process. 
                If your premium is overdue please call us on 1300 209 088
              </GelParagraph>
            </GelRowLayout>
          </GelListItem>
        </GelList>
      </GelRowLayout>
    );
  }
  return (
    <GelRowLayout gutter={getGelTokens().global.sizeBaseX5}>
      <GelHeading6 as="h3" style={h3FontSizeStyle}>
        Make a one off payment
      </GelHeading6>
      <CreditCard policyNumber={policyNumber} premiumOverdue={premiumOverdue} />
    </GelRowLayout>
  );
});

export default OneOff;
