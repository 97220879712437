import React, { useContext, useState } from "react";
import { Brands } from ".";
import helpers from '../Shared/helpers';
import {
  GelContainerLite,
  GelParagraph,
  GelScreenDetectorContext,
  GelBoxLayout,
  GelLink,
  GelRowLayout
} from '@tal-gel/components';


const FooterLinks = ({ brand }) => {
  const links = ["About", "Privacy Policy", "Security", "Complaints", "Disclaimer"];

  const constructedLinks = () => { return links.map((article, index) => (<GelLink key={index} onClick={() => onLinkClick(index)}>{article}</GelLink>)) };

  const { screen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext);
  const isLargeScreen = isLargerThanSmScreen(screen);
  const [displayText] = useState(helpers.SetCurrentBrandText(brand)); 

  function onLinkClick(event) {
    var returnUrl = '';
    switch (event) {
      case 0:
        returnUrl = (displayText.aboutUs)
        break;
      case 1:
        returnUrl = (displayText.privacyPolicy);
        break;
      case 2:
        returnUrl = (displayText.security);
        break;
      case 3:
        returnUrl = (displayText.complaints);
        break;
      case 4:
        returnUrl = (displayText.disclaimer);
        break;
      default:
        returnUrl = '';
        break;
    }
    window.open(returnUrl, "_blank");
  };


  return isLargeScreen ? <GelBoxLayout distribution="center" gap="large" space="auto" >{constructedLinks()}</GelBoxLayout>
    : <GelRowLayout gutter="small">{constructedLinks()}</GelRowLayout>
};

export default FooterLinks;