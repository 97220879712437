import { useEffect, useState } from 'react';
import envConfig from '../env.config';
import getJWTToken from './getJWTToken';

const useQuery = ({ query }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
       
        const token = await getJWTToken();

        if (token) {
          const response = await fetch(envConfig.API_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Headers':'*',
              'Ocp-Apim-Subscription-Key':envConfig.API_KEY,
              'ECSS_Auth': token
            },
            body: JSON.stringify({
              query,
              variables:
              {
                pageNumber: 1 ,
                pageSize: 12 
              }
            })
          });
          if (response.ok) {
            setData(await response.json());
          } else {
            throw new Error(response.statusText);
          }
        } else {
          throw new Error('Invalid token')
        }
      } catch(error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { loading, error, data };
};

export default useQuery;
