import { GET_POLICIES } from "./queries";
import envConfig from "../env.config";
import getJWTToken from './getJWTToken';

export async function paginationQuery({pageNumber =1 , pageSize =12,financialYears }) {   

  let data, errorMsg;
    try{
       
      const token = await getJWTToken();
       
        if (token){
        const response = await fetch(envConfig.API_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Headers':'*',
              'Ocp-Apim-Subscription-Key':envConfig.API_KEY,
              'ECSS_Auth': token
            },
            body: JSON.stringify({
              query:GET_POLICIES,
              variables:
              {
                pageNumber: pageNumber ,
                pageSize: pageSize ,               
                financialYears: financialYears
              }
            })
          });
          if (response.ok) {
            data = await response.json();            
          } else {            
            throw new Error(response.statusText);
          } 
        } 
        else {
          throw new Error('Invalid token')
        }           
    }
    catch (error) {      
        errorMsg = error;        
      } 
     return {data, errorMsg} ;
}