import React,{useContext, useEffect, useState, Button} from 'react';
import {
  GelPageLayout,
  GelPageHeader,
  GelPageContent,
  GelPageFooter,
  GelHeading6,
  GelHeading5,
  GelParagraph,
  GelBoxLayout,
  GelMultiStepForm,
  GelLink,
  GelSpan,GelButton,
  GelScreenDetectorContext
} from '@tal-gel/components';
import { GelThemeProvider, getGelTokens } from '@tal-gel/theming';
import { Text } from '../components/Shared';

import helpers from '../components/Shared/helpers';
import CustomContainer from '../components/Shared/CustomContainer';

import {
  GelScreenDetector, GelRowLayout,
  GelHeading3
} from '@tal-gel/components';

import env from '../env.config';
import Navbar from "../components/UnauthenticatedPayments/Navbar";
import { Brands } from '../components/Shared';
import Footer from '../components/UnauthenticatedPayments/Footer';

import { key as tal } from '@tal-gel/theming-plugin-tal';

const NotFoundPage = () => { 
  
  helpers.SetPageTitle('Server', 'Page not found');

  return (<GelThemeProvider theme={tal} mode={process.env.NODE_ENV}> 
  <GelScreenDetector>
  <GelPageLayout>
        <GelPageHeader>
        </GelPageHeader>       
        <GelPageContent fixed style={{ backgroundColor: getGelTokens().global.themeColorGrayT05 }}>             
           <CustomContainer
              width="60%"
              mt={getGelTokens().global.sizeBaseX6}
              mb={getGelTokens().global.sizeBaseX6}
              ml='auto'
              mr='auto'
              padding={`${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX8}px`}
              borderRadius={2}
              backgroundColor={getGelTokens().global.themeColorBackgroundDefault}>              
              <GelHeading6 as="h2">
                  Page Not Found
              </GelHeading6>
              <br/>
              <GelParagraph style={{ marginBottom: getGelTokens().global.sizeBaseX8 }}>
              We are sorry, the page you requested cannot be found.
              </GelParagraph>           
          </CustomContainer>
         </GelPageContent>
        <GelPageFooter>
        </GelPageFooter>
      </GelPageLayout>
    </GelScreenDetector>
  </GelThemeProvider>    
);
}



export default NotFoundPage;