export class TextConfig {
    constructor() {
        this.brand = null;
        this.title = null;
        this.parentBrand = null;
        this.parentBranchPrivacyPolicy=null;
        this.phoneNumber = null;
        this.privacyPolicy = null;
        this.terms = null;
        this.pleaseNote = null;
        this.footer = null;
        this.aboutUs = null;
        this.security = null;
        this.complaints = null;
        this.disclaimer = null;
        this.contactTime = null;
        this.contactTimeFormat = null;
        this.disclaimerBrand = null;
    };
}