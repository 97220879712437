
import React from "react";
import { Brands } from "./";
import { getGelTokens } from "@tal-gel/theming";

const imageTal = '/img/tal-logo.svg';
const imageInsuranceLine = '/img/il-logo.avif';
const imageAsteron = '/img/asteron-logo.svg';
const imageVma = '/img/vma-logo.gif';
const imageNib = '/img/nib-logo.svg'; 
const imageSuncorp = '/img/suncorp-logo.svg'; 
const imageGio = '/img/gio-logo.svg';
const imageAPIA = '/img/apia-logo.svg';
const imageAAMI = '/img/aami-logo.svg';
const imageNRMA = '/img/nrma-logo.svg';

export class LogoConfig {
    constructor(src, alt, height, navHeight) {
        this.Source = src;
        this.Alt = alt;
        this.Height = height;
        this.NavbarHeight = navHeight;
    };

    SetNavbarHeight = () => this.NavbarHeight ?? getGelTokens().global.sizeBaseX14;
    
    SetLogo = () => <img src={this.Source} height={this.Height} alt={this.Alt} />;
}

const logoConfig = {
    getLogoPathBasedonBrand: function(brand) { 

        switch (brand) {
            case Brands.IL:
               return new LogoConfig(imageInsuranceLine, "Insuranceline Logo", getGelTokens().global.sizeBaseX10);
            case Brands.ASTERON:
                return new LogoConfig(imageAsteron, "Asteron Logo", getGelTokens().global.sizeBaseX11);
            case Brands.VMA:
                return new LogoConfig(imageVma, "Virgin Money Australia Logo", getGelTokens().global.sizeBaseX11);
            case Brands.NIB:
                return new LogoConfig(imageNib, "NIB Logo", getGelTokens().global.sizeBaseX11);   
            case Brands.SUNCORP:
                return new LogoConfig(imageSuncorp, "Suncorp Logo", getGelTokens().global.sizeBaseX11);
            case Brands.GIO:
                return new LogoConfig(imageGio, "GIO Logo", getGelTokens().global.sizeBaseX11);
            case Brands.APIA:
                return new LogoConfig(imageAPIA, "APIA Logo", getGelTokens().global.sizeBaseX17, 68);
            case Brands.AAMI:
                return new LogoConfig(imageAAMI, "AAMI Logo", getGelTokens().global.sizeBaseX11);
            case Brands.NRMA:
                return new LogoConfig(imageNRMA, "NRMA Logo", getGelTokens().global.sizeBaseX15, 90);
            default:
                return new LogoConfig(imageTal, "TAL Logo", getGelTokens().global.sizeBaseX6);
        }
    }
}

export default logoConfig;