let envConfig;

if (process.env.NODE_ENV !== 'production') { // LOCAL dev environment
  envConfig = {
    //API_URL: 'https://dev.api.tal.com.au/ecss-payments/graphql/',
    API_URL: 'https://localhost:44369/graphql/',
    API_INTERNAL_URL: 'https://localhost:44369/graphql/',
    APIToken_PATH: '/Membership/GetJWTtoken',
    API_KEY: 'e812074a79f549579526c055c60db49f',
    BPOINT_URL: 'https://bpoint.uat.linkly.com.au/rest/clientscripts/api.js',
    BPOINT_TIMEOUT: '30000',
    BPOINT_TRIES: '2',
    RECAPTCHA_SITEKEY: '6LeM0BMnAAAAANF3wUIbyzmUgYBBJY469Pycmroj',
    RECAPTCHA_INTERNAL_SITEKEY: '6LeYXEAnAAAAAE1gjrvta1sgwWlzooiQeYihziqc',
    AAD_CLIENTID: '843e7907-7667-47fe-be9e-a5b4c8103e41',
    AAD_AUTHORITY: 'https://login.microsoftonline.com/fedfd771-b82e-47b1-a705-959f02fe7ded',
    ADOBE_SCRIPT_URL: 'https://assets.adobedtm.com/3236b84eee38/5dafd12ae923/launch-05c92206461e-staging.min.js',
    ADOBE_SCRIPT_URLS: '{"default": "https://assets.adobedtm.com/3236b84eee38/d14128d3cba0/launch-800cbd3af05e-staging.min.js", "tal": "https://assets.adobedtm.com/3236b84eee38/5dafd12ae923/launch-05c92206461e-staging.min.js"}',
    ENVIRONMENT_NAME: 'Dev',
    ACTIVE_BRANDS: 'TAL|IL|ASTERON|VIRGINMONEY|NIB|SUNCORP|GIO|APIA|AAMI|NRMA'
    
  };
} else {
  envConfig = {
    // TODO: Change this api url for environments not LOCAL dev.
    //
    // Note that we may want to replace the value with an Octopus variable
    // (eg: API_URL: '#{API_URL}'),
    // so that we can assign different values to this variable in Octopus for
    // different Octopus environments (Dev1, QA1, Prod, etc.).
    API_URL: '#{API_URL}',
    APIToken_PATH: '#{APIToken_PATH}',
    API_KEY: '#{API_KEY}',
    BPOINT_URL:'#{BPOINT_URL}',
    BPOINT_TIMEOUT: '#{BPOINT_TIMEOUT}',
    BPOINT_TRIES: '#{BPOINT_TRIES}',
    RECAPTCHA_SITEKEY: '#{RECAPTCHA_SITEKEY}',
    RECAPTCHA_INTERNAL_SITEKEY: '#{RECAPTCHA_INTERNAL_SITEKEY}',
    API_INTERNAL_URL: '#{API_INTERNAL_URL}',
    AAD_AUTHORITY: '#{AAD_AUTHORITY}',
    AAD_CLIENTID: '#{AAD_CLIENTID}',
    ADOBE_SCRIPT_URL: '#{ADOBE_SCRIPT_URL}',
    ADOBE_SCRIPT_URLS: '#{ADOBE_SCRIPT_URLS}',
    ENVIRONMENT_NAME: '#{ENVIRONMENT_NAME}',
    ACTIVE_BRANDS: '#{ACTIVE_BRANDS}'
  };
}

export default envConfig;
