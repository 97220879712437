const GET_POLICIES = `
query policy($pageNumber: ID, $pageSize: ID, $financialYears :[String]) {
  userPolicies(policyStatus: "InForce", 
  pageNumber: $pageNumber, 
  pageSize: $pageSize,
  financialYears :$financialYears ) {
    productName
    customerPolicyNumber
    isPlatformPolicy
    billingDetails {
      isOverdue
      amountToDisplay
      frequencyFriendlyName
      nextPaymentDetails {
        amount
        dueDate
        paymentMethod
      }
      creditCardList {
        accountNumber
      }
      directDebitList {
        accountNumber
        accountHolderName
      }
      bpayDetailList {
        accountNumberId
        routingNumberId
      }
    }
    paymentHistoryDetails {
      currentPage
      pageSize
      totalCount
      totalPages
      hasNext
      hasPrevious
      paymentHistoryDetailsList {
        dateProcessed
        amount
        description
      }
    }
  }
}
`;

export {
  GET_POLICIES
};
