import React, {useContext} from "react";
import { GelScreenDetectorContext } from "@tal-gel/components";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { Brands, BussinessCode } from "./enums";

import { TALText } from "./TextConfig/TALText";
import { InsurancelineText} from "./TextConfig/InsurancelineText";
import { AsteronText } from "./TextConfig/AsteronText";
import { VirginMoneyAustraliaText } from "./TextConfig/VirginMoneyAustraliaText";
import { NIBText } from "./TextConfig/NIBText";
import { SuncorpText, SuncorpOffSaleText, SuncorpOnSaleText, SuncorpRetailText } from "./TextConfig/SuncorpText";
import { GIOText  } from "./TextConfig/GIOText";
import { APIAOffSaleText, APIAOnSaleText, APIAText } from "./TextConfig/APIAText";
import { AAMIOffSaleText, AAMIOnSaleText, AAMIText } from "./TextConfig/AAMIText";
import { NRMAText } from "./TextConfig/NRMAText";


import { key as tal } from '@tal-gel/theming-plugin-tal';
import { key as il } from '@tal-gel/theming-plugin-il-2022';
import { key as astn } from '@tal-gel/theming-plugin-asteron';
import { key as vma } from '@tal-gel/theming-plugin-vma';
import { key as nib } from '@tal-gel/theming-plugin-nib';
import { key as sun } from '@tal-gel/theming-plugin-suncorp';
import { key as gio } from '@tal-gel/theming-plugin-gio';
import { key as apia } from '@tal-gel/theming-plugin-apia';
import { key as aami } from '@tal-gel/theming-plugin-aami';
import { key as nrma } from '@tal-gel/theming-plugin-nrma';

const helpers = {
    getTodayDate: function() {     
        return new Date().toISOString().slice(0, 10);
    },
    getDateInMMddYYYY: (validDate) => {
        const addZero = (num) => {
            return num<10 ? '0'+num:''+num;
        };
        
        let date = new Date(validDate);
        return `${addZero(date.getDate())}${addZero(date.getMonth() + 1)}${date.getFullYear()}`;
    },    
    GetComponentScreenWidth: function() {
        const { screen, isLargerThanSmScreen, isLargerThanLgScreen, isLargerThanMdScreen } = useContext(GelScreenDetectorContext);
        if(isLargerThanLgScreen(screen))
            return "60%";
        if(isLargerThanMdScreen(screen))
            return "80%";
        return "100%";
    },
    IsInternalUrl: () => {
        return window.location.hostname.toLowerCase().includes("internal-");
        //return true; 
    },
    GetCurrentBrand: (adjustBrandCode = true) => {
        let brand = window.parent.location.pathname.split('/').at(1).toLowerCase().trim();
        if(adjustBrandCode)
            return helpers.AdjustBrandCode(brand);
        return brand;
    },
    AdjustBrandCode: (brand) => {        
        if (brand === 'asteron')
            return 'astn';
        else if (brand === 'suncorp')
            return 'sun';
        else if (brand === 'apia')
            return 'apa';
        else if (brand === 'aami')
            return 'aam';
        else if (brand === 'virginmoney')
            return 'vma';
        else if (brand==='nrma')
            return 'nrm';
        return brand;
    },
    SetAuthCookies: (authToken) => {
        Cookies.set('authToken', authToken, { expires: 1/48, secure: true, sameSite: 'Lax' });
    },
    GetAuthTokenCookies: () => {
        return Cookies.get('authToken');
    },
    SetCurrentBrandText: (brand, businessArea = null, sourceSystemCode = null ) => {
        switch (brand) {
            case Brands.IL:
                return new InsurancelineText();
            case Brands.ASTERON:
                return new AsteronText();
            case Brands.VMA:
                return new VirginMoneyAustraliaText();
            case Brands.NIB:
                return new NIBText();
            case Brands.GIO:
                return new GIOText();
            case Brands.APIA:                
                if (sourceSystemCode === 'ETH')
                    return new APIAOnSaleText();
                else if (sourceSystemCode === 'LFH')
                    return new APIAOffSaleText();
                return new APIAText();  
            case Brands.AAMI:
                if (sourceSystemCode === 'ETH')
                    return new AAMIOnSaleText();
                else if (sourceSystemCode === 'LFH')
                    return new AAMIOffSaleText();
                return new AAMIText();     
            case Brands.NRMA:
                return new NRMAText();     
            case Brands.SUNCORP:
                if (BussinessCode.RETAIL === businessArea)
                    return new SuncorpRetailText();
                else if (sourceSystemCode === 'ETH')
                    return new SuncorpOnSaleText();
                else if (sourceSystemCode === 'LFH')
                    return new SuncorpOffSaleText();
                return new SuncorpText();
            default:
                return new TALText();
        }
    },
    EnableReceipt: (brand) => {
        const receiptEnable = ['tal', 'il', 'astn', 'aam', 'aami', 'apa', 'apia', 'sun', 'sunc','vma','nib','gio','nrm']
        return receiptEnable.includes(brand.toLowerCase())
    },
    AppendScriptToHead: (scriptUrl, ScriptLoaded) => {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;
        script.addEventListener('load', () => ScriptLoaded());
        document.head.appendChild(script);
    },
    SetPageTitle: (brand, titleText) => {
        let brandText = helpers.SetCurrentBrandText(brand);
        window.parent.document.title = brandText.title  + ": " + titleText;
    },  
    SetFavicon: (brand) => {
        let link = window.parent.document.querySelector("link[rel~='icon']");
        if(!link){
            link = window.parent.document.createElement('link');
            link.rel = 'icon';
            window.parent.document.getElementsByTagName('head')[0].appendChild(link);
          } 
        link.href = "/favicon" + brand.toUpperCase() + ".ico";
    },
    GetBrandTheme: (brand) => {
        switch (brand) {
            case Brands.IL:
                return il;
            case Brands.ASTERON:
                return astn;
            case Brands.VMA:
                return vma;
            case Brands.NIB:
                return nib;
            case Brands.GIO:
                return gio;
            case Brands.SUNCORP:
                return sun;
            case Brands.APIA:
                return apia;
            case Brands.AAMI:
                return aami;
            case Brands.NRMA:
                return nrma;    
            default:
                return tal;
        }
    }
};

export default helpers;