import React, { useContext } from "react";
import { GelRowLayout, GelButton, GelHeading6, GelParagraph } from '@tal-gel/components';

import { h4FontSizeStyle } from '../Shared';

const CreditCard = React.memo(({ policyNumber, premiumOverdue = {}}) => {
  const onPayNowClick = () => {
    window.open(
      `/dashboard/policy-details/make-a-payment/pay-via-credit-card?policyId=${policyNumber}`,
      '_self'
    );
  };
  return (
    <GelRowLayout gutter="small">
      <GelHeading6 as="h4" style={h4FontSizeStyle}>
        Credit card
      </GelHeading6>
      
      <GelRowLayout gutter="medium">
        <GelParagraph>
          You can pay now via VISA or Mastercard
        </GelParagraph>
        <GelButton primary medium danger ={premiumOverdue.isOverdue} onClick={onPayNowClick}>
          Pay now
        </GelButton>
      </GelRowLayout> 
    </GelRowLayout>
  );
});

export default CreditCard;


