import envConfig from '../env.config';

export default async function getJWTToken() {
    let token;

    const currentHost = `${window.location.protocol}//${window.location.hostname}`;
 
    const tokenResponse = await fetch(currentHost + envConfig.APIToken_PATH, { method: 'GET' });

    if (tokenResponse.ok) {
        token = await tokenResponse.text();
    } else {
        throw new Error(tokenResponse.statusText);
    }

    return token;
}