import React, { useState, useEffect } from 'react';
import { GelThemeProvider, getGelTokens } from '@tal-gel/theming';
import { key as tal } from '@tal-gel/theming-plugin-tal';
import { key as il } from '@tal-gel/theming-plugin-il-2022';

import { 
  GelScreenDetector, GelRowLayout, 
  GelHeading3, GelBreadcrumbs 
} from '@tal-gel/components';

import PaymentOptions from '../components/PaymentOptions/PaymentOptions';
import SelectPolicy from '../components/SelectPolicy/SelectPolicy';
import NextPayment from '../components/SelectPolicy/NextPayment';
import PaymentTabs from '../components/SelectPolicy/PaymentTabs';
import useQuery from '../services/useQuery';
import { GET_POLICIES } from '../services/queries';
import { Brands } from '../components/Shared';

const PaymentsPage = () => {
  /**
   * Gets the current brand from the Sitecore module
   * If empty, defaults to TAL brand
   */
   const getCurrentBrand = () => {
    const element = document.getElementById("currentBrand");
    return element != null ? element.value.toLowerCase() : Brands.TAL;
  };
  
  const currentBrand = getCurrentBrand();

  const { loading, error, data } = useQuery({
    query: GET_POLICIES
  }); 

  const [policies, setPolicies] = useState();
  useEffect(() => {
    if (data) {      
      setPolicies(data.userPolicies.map(policy => ({
        label: `${policy.productName} - ${policy.customerPolicyNumber}`,
        value: policy.customerPolicyNumber
      })).sort((a, b) => { 
        // sort on label alphabetically asc
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      }));
    }
  }, [data]);

  const [selectedPolicy, setSelectedPolicy] = useState();
  /**
   * Callback for when a selection is made from the policy dropdown
   */
  const onPolicySelected = customerPolicyNumber => {
    // find the policy selected
    setSelectedPolicy(data.userPolicies.filter(policy => {
      return policy.customerPolicyNumber === customerPolicyNumber
    })[0]);
  };
  const isHistoryReturned = () => {        
    return (
      selectedPolicy.paymentHistoryDetails && 
      selectedPolicy.paymentHistoryDetails.paymentHistoryDetailsList.length > 0
    );
  }

  /**
   * Retrieves the next payment detail from the policy selected
   */
  const getNextPaymentDetail = () => {
    if (selectedPolicy && selectedPolicy.billingDetails) {
      const nextPaymentDetail = {
        customerPolicyNumber: selectedPolicy.customerPolicyNumber,
        productName: selectedPolicy.productName,
        ...selectedPolicy.billingDetails.nextPaymentDetails
      };
      const isOverdue = selectedPolicy.billingDetails.isOverdue;   
      const accountNumber = (
        selectedPolicy.billingDetails.directDebitList && 
        selectedPolicy.billingDetails.directDebitList[0] &&
        selectedPolicy.billingDetails.directDebitList[0].accountNumber
      );
      const creditCardNumber = (
        selectedPolicy.billingDetails.creditCardList && 
        selectedPolicy.billingDetails.creditCardList[0] &&
        selectedPolicy.billingDetails.creditCardList[0].accountNumber
      );
      
      if (nextPaymentDetail.paymentMethod === 'DebitOrder') {
        if (accountNumber) {
          return {
            ...nextPaymentDetail,
            paymentMethod: 'DD',
            number: accountNumber,
            isOverdue
          };
        } else if (creditCardNumber) {
          return {
            ...nextPaymentDetail,
            paymentMethod: 'CC',
            number: creditCardNumber,
            isOverdue
          };
        } 
      }
      return {
        ...nextPaymentDetail,
        isOverdue
      }
    }
    return; // if there is no policy selected
  };

  /**
   * Retrieves policy number from the policy selected
   */
  const getPolicyNumber = () => {
    return selectedPolicy && selectedPolicy.customerPolicyNumber;
  };

  /**
   * Retrieves direct debit detail from the policy selected
   */
  const getDirectDebitDetail = () => {
    if (selectedPolicy && selectedPolicy.billingDetails) {
      const { paymentMethod, number } = getNextPaymentDetail();
      const accountName = (
        selectedPolicy.billingDetails.directDebitList && 
        selectedPolicy.billingDetails.directDebitList[0] &&
        selectedPolicy.billingDetails.directDebitList[0].accountHolderName
      );
      if (paymentMethod && number) {
        return {
          paymentMethod, 
          number,
          frequency: selectedPolicy.billingDetails.frequencyFriendlyName,
          accountName
        };
      }
    }
    return;
  };

  /**
   * Retrieves bpay details from the policy selected
   */
  const getBpayDetail = () => {
    if (selectedPolicy && 
      selectedPolicy.billingDetails && 
      selectedPolicy.billingDetails.bpayDetailList) 
    {      
      return selectedPolicy.billingDetails.bpayDetailList[0];
    }
    return; // if there is no policy selected or no bpay details
  };

  /**
   * Uses the current brand to set the theme
   */
   const isTalBrand = () => currentBrand === Brands.TAL;
   const getCurrentBrandTheme = () => isTalBrand() ? tal : il;

   const getOneOffVisibility = () => {
     return isTalBrand() ||
            (selectedPolicy &&
            selectedPolicy.billingDetails &&
            selectedPolicy.billingDetails.frequencyFriendlyName !== "Fortnightly");  
   }

   /**
    * Retrieves payment history details from selected policy
    */
   const getpaymentHistoryList = () => {
     if (selectedPolicy) {
      const PaymentHistoryList = {
        historyList: selectedPolicy.paymentHistoryDetails,
        isHistoryReturned: isHistoryReturned()
      };     
      return PaymentHistoryList;
    }
  }

  return (
    <GelThemeProvider theme={getCurrentBrandTheme()} mode={process.env.NODE_ENV}>
      <GelScreenDetector>
      
        <GelRowLayout gutter="large">
          <GelRowLayout gutter={getGelTokens().global.sizeBaseX5}>
            <GelHeading3 
              data-testid="payment-header"
              as="h1" inverse={isTalBrand()}
              style={{
                fontSize: 30,
                color: !isTalBrand() && getGelTokens().brand.brandColorPrimary2
              }} // not typical GEL, to match current ECSS
            >
              Payments
            </GelHeading3>

            <GelBreadcrumbs routes={[
              { label: 'HOME', to: '/dashboard' },
              { label: 'Payments' },
            ]} directLink inverse={isTalBrand()} />
          </GelRowLayout>
          
          <GelRowLayout>
            <SelectPolicy 
              brand={currentBrand}
              loading={loading}
              error={error}
              policies={policies} 
              onPolicySelected={onPolicySelected} 
            />
            { selectedPolicy && <>
              <NextPayment  
                nextPaymentDetail={getNextPaymentDetail()} 
              />
            { isTalBrand() && <>  
              <PaymentTabs
                brand={currentBrand}
                selectedPolicy={selectedPolicy}
                paymentHistoryList={getpaymentHistoryList()}
                policyNumber={getPolicyNumber()}
                directDebitDetail={getDirectDebitDetail()}
                bPayDetail={getBpayDetail()} 
                premiumOverdue ={getNextPaymentDetail()}
                showOneOff={getOneOffVisibility()}
              />
              </> }
                { (!isTalBrand() && !selectedPolicy.isPlatformPolicy) && <>
              <PaymentOptions
                brand={currentBrand}
                policyNumber={getPolicyNumber()}
                directDebitDetail={getDirectDebitDetail()}
                bPayDetail={getBpayDetail()} 
                premiumOverdue ={getNextPaymentDetail()}
                showOneOff={getOneOffVisibility()}
              />
               </> }
            </> }
          </GelRowLayout> 
        </GelRowLayout>
      </GelScreenDetector>
    </GelThemeProvider>
  ); 
}

export default PaymentsPage;
